import { DEFAULT_ROUTE } from '$constants'

import { updateCurrent } from '$store/session'
import { TokenDetail } from '$store/session/tokens'

import useReturnTo from './useReturnTo'

/**
 * Used on `Login` pages.
 *
 * After successful logged in,
 * - mark the new session as active profile.
 * - Redirects to `?return_to=...` or fallback to `DEFAULT_ROUTE` route.
 */
export const useSuccessLogin = () => {
  const redirect = useReturnTo()

  const action = (id: TokenDetail['id']) => {
    updateCurrent(id)

    redirect(DEFAULT_ROUTE)
  }

  return action
}
