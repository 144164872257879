import Axios, {
  AxiosResponse,
  CreateAxiosDefaults,
  AxiosInstance,
  AxiosError
} from 'axios'
import { isEmpty } from 'lodash-es'

import { filterValues } from '$app/utilities'

import {
  InterceptorErrorResponse,
  ErrorResponseWatcher,
  ResponseInterceptor,
  RequestInterceptor
} from './types'

export type Token = string | null

export class CommonAPI {
  protected api: AxiosInstance

  protected apiResponse: ResponseInterceptor

  protected apiRequest: RequestInterceptor

  protected errorResponseWatchers: ErrorResponseWatcher[] = []

  constructor(defaults?: CreateAxiosDefaults) {
    this.api = Axios.create({
      ...defaults,
      headers: {
        Accept: 'application/json',
        ...(defaults?.headers as object)
      }
    })

    this.apiRequest = this.api.interceptors.request
    this.apiResponse = this.api.interceptors.response
  }

  /**
   * Filter out falsy values from payload.
   */
  public filterRequestPayload = () => {
    const process = this.apiRequest.use(req => {
      if (req.data) req.data = filterValues(req.data)
      if (req.params) req.params = filterValues(req.params)

      return req
    })

    return () => this.apiRequest.eject(process)
  }

  public watchErrorResponse = (
    callback: (err: InterceptorErrorResponse) => void
  ) => {
    const process = this.apiResponse.use(
      response => response,
      error => {
        callback(error)

        throw error
      }
    )

    this.errorResponseWatchers.push(process)

    return () => this.apiResponse.eject(process)
  }

  public destroyErrorResponseWatchers = () => {
    const eject = this.apiResponse.eject

    this.errorResponseWatchers.map(eject)

    this.errorResponseWatchers = []
  }

  public updateToken = (token: Token) => {
    if (isEmpty(token)) {
      delete this.api.defaults.headers.common['Authorization']
    } else {
      this.api.defaults.headers.common['Authorization'] = `Bearer ${token}`
    }
  }

  public hasToken = () => {
    return !isEmpty(this.api.defaults.headers.common['Authorization'])
  }

  public successResolver = <T extends AxiosResponse>({
    data
  }: T): T['data'] => {
    return data
  }

  public errorMessageResolver = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any,
    defaultMessage?: string
  ) => {
    return `${
      error?.response?.data?.message ||
      error?.response?.message ||
      defaultMessage
    }`
  }

  public errorStatusResolver(error: unknown) {
    return (error as Required<AxiosError>).response.status
  }
}
