import { useRequest } from 'ahooks'
import toast from 'react-hot-toast'

import api from '$model/api'

import { AppClientID } from '$services/api/auth'

export const useAppDetail = (client_id?: AppClientID) => {
  return useRequest(
    async () => {
      const { appDetail, successResolver } = api.value.auth

      if (!client_id) return

      return appDetail({ client_id }).then(successResolver)
    },
    {
      cacheKey: `app-detail:${client_id}`,
      refreshDeps: [client_id],
      onError: err => toast.error(api.value.auth.errorMessageResolver(err))
    }
  )
}

export default useAppDetail
