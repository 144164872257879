import { Toaster } from 'react-hot-toast'
import { FC } from 'react'

import { useIsMounted } from '@genie-fintech/ui/hooks'
import { Sprite } from '@genie-fintech/ui/icons'
import { Portal } from '@genie-fintech/ui/components'

import { RouterProvider } from '$contexts/Router'
import useBootstrap from '$actions/useBootstrap'

import { toast } from '$styles/atoms.css'

import SocialSprites from '$icons/SocialSprites'
import Routing from '$app/Routing'

const Contents: FC = () => {
  const isMounted = useIsMounted()

  useBootstrap()

  if (!isMounted) return null

  return (
    <>
      <Sprite />
      <SocialSprites />

      <Routing />

      <Portal>
        <Toaster containerClassName={toast} />
      </Portal>
    </>
  )
}

const App: FC = () => (
  <RouterProvider>
    <Contents />
  </RouterProvider>
)

export default App
