import { FC } from 'react'

import { TagsNoRef } from '@genie-fintech/ui/types'
import { propsWithClassNames } from '@genie-fintech/ui/functions'
import { Avatar, Spinner } from '@genie-fintech/ui/components'

import { noProtocolUrl } from '$app/utilities'

import { container, description, title as titleStyle } from './styles.css'

type AppInfoProps = {
  isLoading: boolean
  title?: string
  domain?: string
  logo?: string
  containerProps?: TagsNoRef<'div'>
}

export const AppInfo: FC<AppInfoProps> = ({
  isLoading,
  logo,
  domain,
  title,
  containerProps
}) => (
  <div {...propsWithClassNames(containerProps, container)}>
    {isLoading && <Spinner />}

    {!isLoading && (
      <>
        <Avatar
          size={48}
          image={logo ? { src: logo } : undefined}
          iconNamespace="Database"
        />

        <div>
          <h2 className={titleStyle}>{title}</h2>

          {domain && <p className={description}>{noProtocolUrl(domain)}</p>}
        </div>
      </>
    )}
  </div>
)

export default AppInfo
