import { Entity, EntityWithID, TypeID } from '@genie-fintech/ui/types'

import { CommonAPI, Token } from './common'
import { GetAPIType } from './types'

const baseURL = `${import.meta.env.VITE_AUTH_API}/api`

/** ********************************************************************
 * Type: Common
 */
export type Profile = EntityWithID<{
  name: string
  email: string
  phone?: string
  profile_photo_url?: string
  address?: string
}>
export type AppClientID = TypeID
type App = Entity<{
  domain: string
  name: string
  logo?: string
  enabled: boolean
  description?: string
}>

/** ********************************************************************
 * Type: App Detail
 */
type AppDetailRequest = {
  client_id: AppClientID
}
type AppDetailResponse = {
  data: App & Pick<AppDetailRequest, 'client_id'>
}

/** ********************************************************************
 * Type: List Apps: Public
 */
type ListPublicAppsResponse = {
  data: App[]
}

/** ********************************************************************
 * Type: Profile
 */
type ProfileResponse = {
  data: Profile
}

/** ********************************************************************
 * Type: Update Password
 */
export type UpdatePasswordRequest = {
  current_password: string
  password: string
}

/** ********************************************************************
 * Type: Check
 */
type CheckResponse = boolean

/** ********************************************************************
 * Type: Login
 */
type LoginRequest = {
  email: string
  password: string
}
type LoginResponse = {
  data: {
    auth_token: string
  }
}

/** ********************************************************************
 * Type: Forgot Password
 */
type ForgotPasswordRequest = {
  email: string
  request_origin: string
}

/** ********************************************************************
 * Type: Reset Password
 */
type ResetPasswordRequest = {
  email: string
  password: string
  token: string
}

/** ********************************************************************
 * Type: Mobile OTP Request
 */
type MobileOTPRequestRequest = {
  phone_code: number
  phone_no: number
}
type MobileOTPRequestResponse = {
  data: {
    reference_number: number
    expired_at: string
  }
}

/** ********************************************************************
 * Type: Mobile OTP Resend
 */
export type MobileOTPResendRequest = {
  reference_number: number
}
type MobileOTPResendResponse = MobileOTPRequestResponse

/** ********************************************************************
 * Type: Mobile OTP Verify
 */
export type MobileOTPVerifyRequest = {
  reference_number: number
  otp: number
}
type MobileOTPVerifyResponse = {
  data: {
    auth_token: string
  }
}

/** ********************************************************************
 * Type: Get Code
 */
export type GetCodeRequest = {
  client_id: AppClientID
  redirect_uri: string
  code_challenge: string
  code_challenge_method: string
  response_type: string
  scope?: string
  state?: string
}

type GetCodeResponse = {
  data: {
    code: string
  } & Pick<GetCodeRequest, 'state'>
}

export class AuthAPI extends CommonAPI {
  constructor(token: Token) {
    super({ baseURL })

    this.filterRequestPayload()

    this.updateToken(token)
  }

  public profile = () => {
    return this.api.get<ProfileResponse>('/profile')
  }

  public updatePassword = (data: UpdatePasswordRequest) => {
    return this.api.put('/profile/passwords', data)
  }

  public check = () => {
    return this.api.get<CheckResponse>('/oauth/is-authenticated')
  }

  public login = (data: LoginRequest) => {
    return this.api.post<LoginResponse>('/oauth/initialize', data)
  }

  public forgotPassword = (data: ForgotPasswordRequest) => {
    return this.api.post('/forgot-password', data)
  }

  public resetPassword = (data: ResetPasswordRequest) => {
    return this.api.post('/reset-password', data)
  }

  public mobileOTPRequest = (data: MobileOTPRequestRequest) => {
    return this.api.post<MobileOTPRequestResponse>(
      '/oauth/mfa/sms/request',
      data
    )
  }

  public mobileOTPResend = (data: MobileOTPResendRequest) => {
    return this.api.post<MobileOTPResendResponse>('/oauth/mfa/sms/resend', data)
  }

  public mobileOTPVerify = (data: MobileOTPVerifyRequest) => {
    return this.api.post<MobileOTPVerifyResponse>('/oauth/mfa/sms/verify', data)
  }

  public logout = () => {
    return this.api.post('/logout')
  }

  public getCode = (data: GetCodeRequest) => {
    return this.api.post<GetCodeResponse>('/oauth/authorize', data)
  }

  public listPublicApps = () => {
    return this.api.get<ListPublicAppsResponse>('/applications')
  }

  public appDetail = ({ client_id, ...data }: AppDetailRequest) => {
    return this.api.get<AppDetailResponse>(`/applications/${client_id}`, {
      data
    })
  }
}

export type TypeAuthAPI = GetAPIType<AuthAPI>
