import { FC } from 'react'

import { TagsNoRef } from '@genie-fintech/ui/types/jsx'
import { propsWithClassNames } from '@genie-fintech/ui/functions'

import { status } from '@genie-fintech/ui/style/element'
import { Avatar } from '@genie-fintech/ui/components'
import { Icon } from '@genie-fintech/ui/icons'

import {
  container,
  nav,
  name as nameStyle,
  username as usernameStyle,
  aside,
  icon,
  intro
} from './style.css'

export type AccountPreviewProps = {
  name: string
  noAccess?: boolean
  image?: string
  username?: string
  containerProps?: TagsNoRef<'button'>
}

export const AccountPreview: FC<AccountPreviewProps> = ({
  name,
  image,
  noAccess,
  username,
  containerProps
}) => (
  <button type="button" {...propsWithClassNames(containerProps, container)}>
    <Avatar image={{ src: image }} />

    <nav className={nav}>
      <span className={intro}>
        <h4 className={nameStyle}>{name}</h4>
        {noAccess && (
          <span
            className={status({
              size: 'small',
              kind: 'warning',
              type: 'faint'
            })}
          >
            No Access
          </span>
        )}
      </span>

      <p className={usernameStyle}>{username}</p>
    </nav>

    <aside className={aside}>
      <Icon namespace="Forward" className={icon} />
    </aside>
  </button>
)

export default AccountPreview
