import { FC, PropsWithChildren } from 'react'

import { trueOrUndefined } from '@genie-fintech/ui/functions'

import useLayoutClass from '$browser/useLayoutClass'
import useIsMounted from '$browser/useIsMounted'
import useSignal from '$actions/useSignal'

import AuthLogo from '$icons/AuthLogo'
import BgCover from '$elements/BgCover'
import Link from '$elements/Link'
import CopyrightText from '$elements/CopyrightText'

import { profile as profileStore } from '$store/profile'

import Nav from './Nav'

import {
  container,
  copyright,
  cover,
  footer,
  header,
  headerInner,
  logo,
  main
} from './styles.css'

export const AdminLayout: FC<PropsWithChildren> = ({ children }) => {
  useLayoutClass({ name: 'AdminLayout' })

  const ready = useIsMounted()
  const profile = useSignal(profileStore)
  const fig = { ...profile }

  return (
    <>
      <section className={container} data-ready={trueOrUndefined(ready)}>
        <header className={header}>
          <div className={headerInner}>
            <Link to="root">
              <a>
                <AuthLogo className={logo} />
              </a>
            </Link>

            <Nav
              user={{
                loading: fig.loading,
                name: fig.data?.name,
                photo: fig.data?.profile_photo_url
              }}
            />
          </div>
        </header>

        <main className={main} children={children} />

        <footer className={footer}>
          <CopyrightText className={copyright} />
        </footer>
      </section>

      <BgCover className={cover} data-ready={trueOrUndefined(ready)} />
    </>
  )
}

export default AdminLayout
