import { FC } from 'react'

import { authMethods } from '$constants/auth'

import useEmailLogin from '$actions/useEmailLogin'
import useAppDetail from '$actions/useAppDetail'
import useSignal from '$actions/useSignal'

import LoginForm from '$hook-forms/LoginForm'
import MobileOTPRequestForm from '$hook-forms/MobileOTPRequestForm'
import useMobileOTPRequest from '$actions/useMobileOTPRequest'

import Link from '$elements/Link'
import TabContents from '$elements/TabContents'
import AppInfo from '$elements/AppInfo'

import session from '$store/session'
import queryParams from '$store/queryParams'

import {
  accountSelectionLink,
  appInfo,
  container,
  forgotLink,
  methods,
  title
} from './styles.css'

export const LoginBlock: FC = () => {
  const {
    params: { client_id, auth_method }
  } = useSignal(queryParams)

  const targetApp = useAppDetail(client_id)
  const isAppTarget = !!client_id
  const isAppInfoLoading = isAppTarget && targetApp.loading
  const isAppLogin = isAppInfoLoading || !!targetApp.data
  const appTitle = targetApp.data?.data.name
  const appLogo = targetApp.data?.data.logo
  const appDomain = targetApp.data?.data.domain

  const { tokenDetails } = useSignal(session)

  const hasTokens = !!tokenDetails.length

  const loginActions = useEmailLogin()
  const otpActions = useMobileOTPRequest()

  return (
    <section className={container}>
      <h2 className={title}>Log In</h2>

      {isAppLogin && (
        <AppInfo
          isLoading={isAppInfoLoading}
          domain={appDomain}
          logo={appLogo}
          title={appTitle}
          containerProps={{ className: appInfo }}
        />
      )}

      <TabContents
        tabs={[
          { id: authMethods[0], name: 'Email' },
          { id: authMethods[1], name: 'Phone Number' }
        ]}
        defaultTabId={auth_method}
        containerProps={{ className: methods }}
      >
        {id => (
          <>
            {id == authMethods[0] && (
              <LoginForm
                {...loginActions}
                preFooter={
                  <Link to="authForgotPassword" className={forgotLink}>
                    Forgot password?
                  </Link>
                }
              />
            )}
            {id == authMethods[1] && <MobileOTPRequestForm {...otpActions} />}
          </>
        )}
      </TabContents>

      {hasTokens && (
        <Link
          to="authAccounts"
          options={({ queryParams }) => ({ queryParams })}
          className={accountSelectionLink}
        >
          Use existing sessions
        </Link>
      )}
    </section>
  )
}

export default LoginBlock
