import { FC } from 'react'

import { useRouteMeta } from '$actions/useRouteMeta'
import { useRouter } from '$actions/useRouter'

import AccountSelectionGuard from '$guards/AccountSelectionGuard'

import AuthLayout from '$layouts/AuthLayout'
import CenteredLayout from '$layouts/CenteredLayout'
import AdminLayout from '$layouts/AdminLayout'

import Views from '$views'
import Root from '$views/Root'
import Login from '$views/Login'
import ForgotPassword from '$views/ForgotPassword'
import ResetPassword from '$views/ResetPassword'
import SmsOtpVerify from '$views/SmsOtpVerify'
import Accounts from '$views/Accounts'
import App from '$views/App'
import ProfileDetail from '$views/ProfileDetail'

export const Routing: FC = () => {
  useRouteMeta()

  const { is404, on, onOneOf } = useRouter()

  const isAuthLayout = onOneOf([
    'auth',
    'authAccounts',
    'authApp',
    'authLogin',
    'authForgotPassword',
    'authResetPassword',
    'authSmsOtpVerify'
  ])
  const isAdminLayout = onOneOf(['profileDetail'])
  const isCenteredLayout = is404 || onOneOf(['root'])

  return (
    <Views>
      {isAuthLayout && (
        <AuthLayout>
          {on('authLogin') && <Login />}

          {on('authForgotPassword') && <ForgotPassword />}

          {on('authResetPassword') && <ResetPassword />}

          {on('authSmsOtpVerify') && <SmsOtpVerify />}

          {on('authApp') && (
            <AccountSelectionGuard>
              <App />
            </AccountSelectionGuard>
          )}

          {on('authAccounts') && <Accounts />}
        </AuthLayout>
      )}

      {isAdminLayout && (
        <AccountSelectionGuard>
          <AdminLayout>{on('profileDetail') && <ProfileDetail />}</AdminLayout>
        </AccountSelectionGuard>
      )}

      {isCenteredLayout && (
        <CenteredLayout>
          {on('root') && <Root />}

          {is404 && '404 / Page not found'}
        </CenteredLayout>
      )}
    </Views>
  )
}

export default Routing
