import { FC, useMemo } from 'react'

import { Spinner } from '@genie-fintech/ui/components'
import { Icon } from '@genie-fintech/ui/icons'

import { buildURL } from '$app/utilities'

import usePageClass from '$browser/usePageClass'
import useAppList from '$actions/useAppList'
import useSignal from '$actions/useSignal'
import useActiveProfileLogout from '$actions/useActiveProfileLogout'

import { ProfilePhoto } from '$elements/ProfilePhoto'
import ChangePasswordButton from '$blocks/ChangePasswordButton'

import lazyToast from '$services/lazyToast'
import { CommonAPI } from '$services/api/common'

import { profile as profileStore } from '$store/profile'

import {
  appListContent,
  appLogo,
  appNameInitial,
  app,
  appLogoCanvas,
  appTitle,
  appContent,
  appDescription,
  appLogoElement,
  profilePreview,
  header,
  profilePreviewFigure,
  profilePreviewFigureRect,
  container,
  profileInfo,
  profileInfoHeader,
  profileDescription,
  profileInfoTitle,
  profileEmail,
  profilePreviewNav,
  profileName,
  profileDescriptionLabel,
  profileDescriptionValue,
  profileInfoLoader,
  main,
  appListHeader,
  appListHeaderLine,
  appListHeaderTitle,
  profileInfoLogout,
  profileLogoutIcon,
  profileChangePassword,
  loaderControl,
  appListEmptyText
} from './styles.css'

const pageName = 'Apps'

const { errorMessageResolver } = new CommonAPI()

export const Apps: FC = () => {
  usePageClass({ name: pageName })

  const profile = useSignal(profileStore)
  const { formatted, isLoading, isEmpty } = useAppList()
  const logoutRequest = useActiveProfileLogout()

  const activeProfile = { ...profile }
  const profileData = activeProfile.data

  const auth_user = profileData?.id

  const withAuthUserQueryParam = useMemo(
    () =>
      formatted.map(({ domain, ...rest }) => ({
        ...rest,
        domain,
        href: buildURL(domain, { queryParams: { auth_user } }).toString()
      })),
    [formatted, auth_user]
  )

  const doLogout = () => {
    lazyToast(logoutRequest.logout(), {
      loading: 'Clearing session..',
      error: errorMessageResolver,
      success: 'Logout success.'
    })
  }

  return (
    <section className={container}>
      <header className={header}>
        <article className={profilePreview}>
          {!activeProfile.loading && (
            <header>
              <ProfilePhoto
                src={profileData?.profile_photo_url}
                className={profilePreviewFigure}
                fallbackRectClassName={profilePreviewFigureRect}
              />

              <nav className={profilePreviewNav}>
                <h2 className={profileName}>{profileData?.name}</h2>
                <p className={profileEmail}>{profileData?.email}</p>
              </nav>
            </header>
          )}

          {activeProfile.loading && <Spinner color="neutral.100" />}
        </article>

        <article className={profileInfo}>
          {!activeProfile.loading && (
            <>
              <header className={profileInfoHeader}>
                <h3 className={profileInfoTitle}>Profile Information</h3>

                <button
                  type="button"
                  title="Logout"
                  className={profileInfoLogout}
                  disabled={logoutRequest.loading}
                  onClick={doLogout}
                >
                  logout
                  <Icon namespace="LogOut" className={profileLogoutIcon} />
                </button>
              </header>

              <div className={profileDescription}>
                <div>
                  <h4 className={profileDescriptionLabel}>Account ID</h4>
                  <p className={profileDescriptionValue}>{profileData?.id}</p>
                </div>
                <div>
                  <h4 className={profileDescriptionLabel}>Email</h4>
                  <p className={profileDescriptionValue}>
                    {profileData?.email}
                  </p>
                </div>

                {profileData?.address && (
                  <div>
                    <h4 className={profileDescriptionLabel}>Address</h4>
                    <p className={profileDescriptionValue}>
                      {profileData.address}
                    </p>
                  </div>
                )}

                <div>
                  <ChangePasswordButton className={profileChangePassword} />
                </div>
              </div>
            </>
          )}

          {activeProfile.loading && (
            <div className={profileInfoLoader}>
              <Spinner color="neutral.100" />
            </div>
          )}
        </article>
      </header>

      <main className={main}>
        <header className={appListHeader}>
          <h2 className={appListHeaderTitle}>Applications</h2>
          <hr className={appListHeaderLine} />
        </header>

        {isLoading && (
          <div className={loaderControl}>
            <Spinner color="absolute.light" />
          </div>
        )}

        {!isLoading &&
          (isEmpty ? (
            <p className={appListEmptyText}>No Apps</p>
          ) : (
            <div className={appListContent}>
              {withAuthUserQueryParam.map(
                ({ id, name, description = '', href, logo }) => (
                  <a
                    key={id}
                    className={app}
                    href={href}
                    title={[name, description].filter(Boolean).join('\n')}
                  >
                    <figure className={appLogo}>
                      {logo ? (
                        <img className={appLogoElement} src={logo} alt={name} />
                      ) : (
                        <span className={appNameInitial}>
                          {name.charAt(0).toUpperCase()}
                          {name.charAt(1).toLocaleLowerCase()}
                        </span>
                      )}

                      <div className={appLogoCanvas} />
                    </figure>

                    <div className={appContent}>
                      {name && <h3 className={appTitle}>{name}</h3>}

                      {description && (
                        <div className={appDescription}>{description}</div>
                      )}
                    </div>
                  </a>
                )
              )}
            </div>
          ))}
      </main>
    </section>
  )
}

export default Apps
