import { FC, PropsWithChildren, useEffect } from 'react'

import { Spinner } from '@genie-fintech/ui/components'

import useRouter from '$actions/useRouter'
import useSignal from '$actions/useSignal'

import queryParams, { clearQueryParamCache } from '$store/queryParams'
import session, { updateCurrent } from '$store/session'
import { status } from '$store/status'

/**
 * Redirects to `authAccounts` when session is inactive. Otherwise, render the children.
 * - When url contain `?auth_user=...` query param, its value will be validated and marked as current `active` session.
 */
export const AccountSelectionGuard: FC<PropsWithChildren> = ({ children }) => {
  const { hasApiActiveSource, hasActiveSession } = useSignal(status)

  const {
    redirectWithReturnTo,
    name,
    queryParams: urlQueryParams
  } = useRouter()

  const { params } = useSignal(queryParams)
  const { tokenStates } = useSignal(session)

  // Favor `urlQueryParams` before using cached `queryParams`
  const requestedAccountId = urlQueryParams.auth_user || params.auth_user
  const hasRequestedAccountId = !!requestedAccountId
  const requestedAccountState = tokenStates.find(
    ({ id }) => requestedAccountId == id
  )?.state
  const requestedAccountIsChecked = !!requestedAccountState?.checked
  const requestedAccountIsAlive = !!requestedAccountState?.alive
  const requestedAccountIsValid = (() => {
    return requestedAccountIsChecked && requestedAccountIsAlive
  })()

  const isAccountSelected = hasApiActiveSource && hasActiveSession

  // Auto-select `requestedAccountId` when session is `alive`
  useEffect(() => {
    if (!requestedAccountIsValid) return

    updateCurrent(requestedAccountId)

    // Clear `auth_user` cache after `requestedAccountId` is being applied.
    // Since it is no longer relevant.
    clearQueryParamCache(['auth_user'])
  }, [requestedAccountIsValid, requestedAccountId])

  useEffect(() => {
    // Wait until fully validated.
    // Only when automatic user selection requested.
    if (hasRequestedAccountId && requestedAccountIsChecked) return

    // Ignore 404
    if (!name) return

    if (!isAccountSelected) {
      redirectWithReturnTo(
        'authAccounts',
        name,
        // Keep query-params
        ({ queryParams }) => ({ queryParams })
      )

      return
    }
  }, [
    hasRequestedAccountId,
    requestedAccountIsChecked,
    isAccountSelected,
    name,
    redirectWithReturnTo
  ])

  if (isAccountSelected) return children

  return (
    <Spinner
      style={{
        width: 'auto',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
      }}
    />
  )
}

export default AccountSelectionGuard
