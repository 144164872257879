import { TokenDetail } from '$store/session/tokens'
import { LiteralUnion } from 'type-fest'

export type AuthMethods = (typeof authMethods)[number]
export const authMethods = ['email', 'phone'] as const

export type AuthQueryParams = {
  auth_user?: TokenDetail['id']
  auth_method?: LiteralUnion<AuthMethods, string>
}
